<template>
    <div>
        <Book/>
    </div>
</template>
<script>
import Book from '@/components/Book.vue'
export default {
    name: 'BookingLandingPage',
    components: {
        // Menu,
        Book
    },
}
</script>
<style lang="">
    
</style>